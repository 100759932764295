import CareEntryListRow, {CareEntryRowVariant} from "@components/CareEntryRow";
import React from "react";

import {useHoveredIndexController} from "../../../../../hooks/useHoveredIndexController";
import {getBorder} from "./border";

interface Props {
  keyPrefix: string;
  rowVariant?: CareEntryRowVariant;
  hideBorders?: boolean;
  loadingRowCount?: number;
}

const getRowKey = (prefix: string, slug: string) => `${prefix}-${slug}`;
const blankResult = {name: "", slug: ""};

const ResultList: React.FC<Props> = ({
  keyPrefix,
  rowVariant,
  hideBorders = false,
  loadingRowCount = 0,
}) => {
  const {activeIndex, setHovered} = useHoveredIndexController();
  return (
    <div className="flex flex-col transition">
      {Array(loadingRowCount)
        .fill(null)
        .map((_, i, arr) => (
          // Normally do not ignore these, but this is only to set a hover style and has no a11y implication
          // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
          <li
            key={getRowKey(keyPrefix, i.toString())}
            className={`list-none ${getBorder(i, activeIndex, arr.length, hideBorders)}`}
            onMouseEnter={() => setHovered(i)}
          >
            <CareEntryListRow
              rank={i + 1}
              cypressKey={`${keyPrefix}-${i}-loading`}
              result={blankResult}
              variant={rowVariant}
              isLoading
            />
          </li>
        ))}
    </div>
  );
};

export default React.memo(ResultList);
