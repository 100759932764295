import Collapse from "../../../../animations/Collapse";
import CareEntryListRow, {
  CareEntryRowVariant,
  ExtendedCareEntrySearchResult,
} from "@components/CareEntryRow";
import React from "react";
import {SelectedLocation} from "@components/CareEntryRow/Control";
import {useTopicStack} from "@components/Reason/CareDiscoverySearch/useTopicStack";
import {CareDiscoveryRowClickReporter} from "@components/Reason/CareDiscoverySearch/careDiscoveryAnalytics";
import {useExtendCareEntry} from "../../../../../hooks/useCareEntryExtensions";

type Props = {
  canSendApptStartEvent?: boolean;
  careEntry: ExtendedCareEntrySearchResult;
  className: string;
  disableAnimation?: boolean;
  firstLocRef?: React.RefObject<HTMLLIElement>;
  hide?: boolean;
  highlightQuery?: string;
  keyPrefix: string;
  index: number;
  maybeAddCareToTopicStack: ReturnType<typeof useTopicStack>["maybeAddToStack"];
  maybeToggleDialog: (shouldRedirect: boolean) => void;
  onMouseEnter: () => unknown;
  reportClickEvent: CareDiscoveryRowClickReporter;
  rowVariant?: CareEntryRowVariant;
  selectedLocation?: SelectedLocation;
  transitionSpeed: number;
  viewId?: string;
};
const Item: React.FC<Props> = ({
  canSendApptStartEvent,
  careEntry,
  className,
  disableAnimation,
  firstLocRef,
  hide,
  highlightQuery,
  index,
  keyPrefix,
  maybeAddCareToTopicStack,
  maybeToggleDialog,
  onMouseEnter,
  reportClickEvent,
  rowVariant,
  selectedLocation,
  transitionSpeed,
  viewId,
}: Props) => {
  const extendedCareEntry = useExtendCareEntry(careEntry);
  return (
    // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
    <li ref={firstLocRef} onMouseEnter={onMouseEnter} className={`list-none ${className}`}>
      <Collapse
        in={!hide}
        appear
        disableAnimation={disableAnimation}
        duration={transitionSpeed}
        unmountOnExit
      >
        <CareEntryListRow
          cypressKey={`${keyPrefix}-${careEntry.slug}`}
          rank={index + 1}
          result={extendedCareEntry}
          selectedLocation={selectedLocation}
          query={highlightQuery}
          variant={rowVariant}
          isLoading={false}
          canSendApptStartEvent={canSendApptStartEvent}
          reportClickEvent={reportClickEvent}
          maybeToggleDialog={maybeToggleDialog}
          maybeAddCareToTopicStack={maybeAddCareToTopicStack}
          viewId={viewId}
        />
      </Collapse>
    </li>
  );
};

export default React.memo(Item);
