import {CareEntryRowVariant, ExtendedCareEntrySearchResult} from "@components/CareEntryRow";
import {useListBoundaryController} from "@components/Locations/useClinicListBoundaryController";
import React, {useCallback, useRef} from "react";

import useMediaQuery from "../../../../../useMediaQuery";
import {CareDiscoveryRowClickReporter} from "../careDiscoveryAnalytics";
import {useTopicStack} from "../useTopicStack";
import {SelectedLocation} from "@components/CareEntryRow/Control";
import ListController from "@page-containers/get-care/components/ListController";
import {useHoveredIndexController} from "../../../../../hooks/useHoveredIndexController";
import {getBorder} from "@components/Reason/CareDiscoverySearch/ResultList/border";
import ResultList from "@components/Reason/CareDiscoverySearch/ResultList/index";

interface Props {
  keyPrefix: string;
  careEntries: ExtendedCareEntrySearchResult[];
  transitionSpeed: number;
  selectedLocation?: SelectedLocation;
  highlightQuery?: string;
  rowVariant?: CareEntryRowVariant;
  hide?: boolean;
  hideBorders?: boolean;
  className?: string;
  disableAnimation?: boolean;
  phoneNumber?: string | null;
  reportClickEvent: CareDiscoveryRowClickReporter;
  maybeToggleDialog: (shouldRedirect: boolean) => void;
  maybeAddCareToTopicStack: ReturnType<typeof useTopicStack>["maybeAddToStack"];
  canSendApptStartEvent?: boolean;
  viewId?: string;
}

const Controlled: React.FC<Props> = ({
  viewId,
  keyPrefix,
  careEntries,
  transitionSpeed,
  selectedLocation,
  highlightQuery,
  rowVariant,
  hide,
  hideBorders = false,
  className = "",
  disableAnimation = false,
  phoneNumber,
  reportClickEvent,
  maybeToggleDialog,
  maybeAddCareToTopicStack,
  canSendApptStartEvent = false,
}) => {
  const shouldBindList = useMediaQuery("sm");
  const firstLocRef = useRef<HTMLLIElement | null>(null);

  const onListReset = useCallback(() => {
    if (firstLocRef.current) {
      firstLocRef.current.scrollIntoView({block: "center", behavior: "smooth"});
    }
  }, [firstLocRef]);
  const {boundList, isMaxed, handleShowMoreClick, start, stop} = useListBoundaryController(
    careEntries,
    4,
    onListReset,
  );

  const toRender = shouldBindList ? boundList : careEntries;
  const showListController = shouldBindList && careEntries.length > 6;

  const {activeIndex, setHovered, unsetHovered} = useHoveredIndexController();

  return (
    <div>
      <ResultList onMouseLeave={unsetHovered} className={className} phoneNumber={phoneNumber}>
        {toRender.map((result, i, arr) => (
          <ResultList.Item
            canSendApptStartEvent={canSendApptStartEvent}
            careEntry={result}
            className={getBorder(i, activeIndex, arr.length + (phoneNumber ? 1 : 0), hideBorders)}
            disableAnimation={disableAnimation}
            firstLocRef={i === 0 ? firstLocRef : undefined}
            hide={hide}
            highlightQuery={highlightQuery}
            keyPrefix={keyPrefix}
            index={i}
            key={`${keyPrefix}-${i}-${result.name}`}
            maybeAddCareToTopicStack={maybeAddCareToTopicStack}
            maybeToggleDialog={maybeToggleDialog}
            onMouseEnter={() => setHovered(i)}
            reportClickEvent={reportClickEvent}
            rowVariant={rowVariant}
            selectedLocation={selectedLocation}
            transitionSpeed={transitionSpeed}
            viewId={viewId}
          />
        ))}
      </ResultList>
      {showListController && (
        <ListController
          handleShowMoreClick={handleShowMoreClick}
          start={start}
          stop={stop}
          isMaxed={isMaxed}
          total={careEntries.length}
        />
      )}
    </div>
  );
};

export default React.memo(Controlled);
